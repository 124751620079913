<template>
  <div class="page">
    <Navbar title="收入资产" :callback="back()" type="SMP" />
    <van-form @submit="submit" class="form">
      <van-field
        label-width="120px"
        v-model="asset.selfMIncome"
        name="个人月收入(元)"
        label="个人月收入(元)"
        placeholder="请输入个人月收入"
        :rules="[{ required: true, message: '请输入个人月收入' }]"
      />
      <van-field
        label-width="120px"
        v-model="asset.selfYIncome"
        name="个人年收入(万)"
        label="个人年收入(万)"
        placeholder="请输入个人年收入"
        :rules="[{ required: true, message: '请输入个人年收入' }]"
      />
      <van-field
        label-width="120px"
        v-model="asset.selfDeposit"
        name="个人存款(万)"
        label="个人存款(万)"
        placeholder="请输入个人存款"
        :rules="[{ required: true, message: '请输入个人存款' }]"
      />
      <van-field
        label-width="120px"
        v-model="asset.homeMIncome"
        name="家庭月收入(元)"
        label="家庭月收入(元)"
        placeholder="请输入家庭月收入"
        :rules="[{ required: true, message: '请输入家庭月收入' }]"
      />
      <van-field
        label-width="120px"
        v-model="asset.homeYIncome"
        name="家庭年收入(万)"
        label="家庭年收入(万)"
        placeholder="请输入家庭年收入"
        :rules="[{ required: true, message: '请输入家庭年收入' }]"
      />
      <van-field
        label-width="120px"
        v-model="asset.homeDeposit"
        name="家庭存款(万)"
        label="家庭存款(万)"
        placeholder="请输入家庭存款"
        :rules="[{ required: true, message: '请输入家庭存款' }]"
      />
      <van-row>
        <van-col offset="4" span="16" class="subbtn">
          <van-button
            :color="COLOR"
            size="small"
            icon="passed"
            native-type="submit"
          >
            提交保存
          </van-button>
        </van-col>
      </van-row>
    </van-form>
    <van-popup :show="loadingShow" style="background-color: transparent"
      ><van-loading size="24px" :color="COLOR" vertical>加载中...</van-loading>
    </van-popup>
    <Share ref="share" />
  </div>
</template>
<script>
import { Form, Field, CellGroup, Divider } from 'vant'
import Navbar from '../../common/Navbar.vue'
import Share from '../../common/Share.vue'
export default {
  components: {
    Navbar: Navbar,
    Share: Share,
    [Form.name]: Form,
    [Field.name]: Field,
    [CellGroup.name]: CellGroup,
    [Divider.name]: Divider
  },
  data () {
    return {
      step: 2,
      personCode: '',
      regions: {},
      loadingShow: false,
      asset: { selfMIncome: 0, selfYIncome: 0, selfDeposit: 0, homeMIncome: 0, homeYIncome: 0, homeDeposit: 0 }
    }
  },
  mounted () {
    var query = this.$route.query
    var code = query.personCode
    if (code !== '' && code !== undefined && code !== null) {
      this.personCode = code
      this.retrieveAttr()
    }
    this.$refs.share.default('main')
  },
  methods: {
    async submit () {
      this.loadingShow = true
      var attrs = [
        { key: 'PSN_AST_SMI', type: 'DTL', content: { amount: this.asset.selfMIncome }, seq: '1' },
        { key: 'PSN_AST_SYI', type: 'DTL', content: { amount: this.asset.selfYIncome }, seq: '1' },
        { key: 'PSN_AST_SD', type: 'DTL', content: { amount: this.asset.selfDeposit }, seq: '1' },
        { key: 'PSN_AST_HMI', type: 'DTL', content: { amount: this.asset.homeMIncome }, seq: '1' },
        { key: 'PSN_AST_HYI', type: 'DTL', content: { amount: this.asset.homeYIncome }, seq: '1' },
        { key: 'PSN_AST_HD', type: 'DTL', content: { amount: this.asset.homeDeposit }, seq: '1' }
      ]
      var pd = {
        personCode: this.personCode,
        attrs: JSON.stringify(attrs)
      }
      const { data: res } = await this.$http.post(this.BMS_URL + '/people/person/editPersonAttr', this.$qs.stringify(pd))
      this.loadingShow = false
      if (res.status === '200') {
        this.$dialog.alert({
          title: '收入资产',
          message: '保存成功'
        }).then(() => {
          this.$router.go(-1)
        })
      } else {
        this.$dialog.alert({
          title: '收入资产',
          message: res.msg
        }).then(() => {
        })
      }
    },
    async retrieveAttr () {
      var pd = { personCode: this.personCode, group: 'AST', type: 'DTL' }
      const { data: res } = await this.$http.post(this.BMS_URL + '/people/person/retrievePersonAttrGroupList', this.$qs.stringify(pd))
      if (res.status === '200') {
        res.data.forEach(element => {
          var obj = JSON.parse(element.content)
          if (element.key === 'PSN_AST_SMI') {
            this.asset.selfMIncome = obj.amount
          } else if (element.key === 'PSN_AST_SYI') {
            this.asset.selfYIncome = obj.amount
          } else if (element.key === 'PSN_AST_SD') {
            this.asset.selfDeposit = obj.amount
          } else if (element.key === 'PSN_AST_HMI') {
            this.asset.homeMIncome = obj.amount
          } else if (element.key === 'PSN_AST_HYI') {
            this.asset.homeYIncome = obj.amount
          } else if (element.key === 'PSN_AST_HD') {
            this.asset.homeDeposit = obj.amount
          }
        })
      }
    },
    back () {
      var url = window.sessionStorage.getItem(this.SESSION_BACKURL)
      window.location.href = url
    }
  }
}
</script>
<style lang="less" scoped>
.step {
  text-align: left;
}
.form {
  padding: 0px 5px;
  margin-bottom: 30px;
}
.title {
  font-size: 13px;
  padding: 8px 0px 0px 10px;
  font-weight: 600;
  text-align: left;
  color: #999;
  height: 30px;
  line-height: 30px;
}
.subbtn {
  margin: 20px auto;
}
</style>
